<template>
	<v-card>
		<v-row style="margin: auto" justify="center">
			<h3 class="mt-3 ml-6">REPORTE </h3>
			
			<v-spacer> </v-spacer>
			<v-btn
				@click="$emit('close')"
				class="mt-3 mr-3"
				small
				fab
				color="error"
				><i style="font-size: 16px" class="fas fa-times"></i
			></v-btn>
		</v-row>
		<v-row>
			<div class="col-md-12 col-sm-12">
				<v-divider></v-divider>
				<v-col align="center" cols="12" lg="12" md="12">
					<v-card-title>                  
						<s-toolbar 
							dark
							:color="'#BAB6B5'"
							style="display: flex"
						>
							<v-btn icon
								fab 
								color="grey-lighten-4"
								@click="exportExcel()"
								x-small >
								<v-icon color="danger">
									fas fa-file-excel
								</v-icon>
							</v-btn>     
						</s-toolbar>
					</v-card-title>
					<v-data-table
						dense
						:headers="headerPrepackingRpt"
						:items="ItemsPrepackingDetailRpt"
						:items-per-page="-1"
						item-key="CidID"
						disable-sort
					>

					</v-data-table>
				</v-col>
					
			</div>
		</v-row>
		
		
			<v-container>
				
				<v-row>
					<v-col class="pb-0">
						
					</v-col>
				</v-row>

				<v-row>
					<v-col lg="12" class="pt-0">
						
					</v-col>
				</v-row>
			</v-container>
		</v-card>

		
	<!-- </div> -->
</template>

<script>
	import _sPrePacking from "@/services/FrozenProduction/FrzChamberPrePacking.js";
    import _sQryConfigurationService from "@/services/QueryManager/QryConfigurationService.js";
    

	export default {
		props: {
			CppID:{
                type: Number,
                required: true,
            }
		},
		components: {  },
		data() {
			return {
				headerPrepackingRpt:[
					{ text: "Fecha", value: "DateIncome", width: 80 },
					// { text: "Hora", value: "HourIncome", width: 80 },
					{ text: "Ubicacion pallet", value: "PalletLocation", width: 80 },
                    { text: "N° Pallet", value: "PalletGroupID", width: 80 },
					// { text: "ID Proveedor", value: "RcfIDs", width: 80 },
                    { text: "ID Trazabilidad", value: "TraceabilityID", width: 80 },
					{ text: "Cliente", value: "CumNombre", width: 80 },
					{ text: "Marca", value: "CbdBrans", width: 80 },
					{ text: "Cultivo", value: "TypeCultiveName", width: 80 },
					{ text: "Variedad", value: "VrtDescription", width: 80 },
					{ text: "Tipo de Corte", value: "TypeCutName", width: 80 },
					{ text: "Presentación", value: "TypePackingName", width: 80 },
                    { text: "N° Cajas", value: "TotalBox", width: 80 },
                    { text: "Peso (Kg)", value: "TotalBagWeight", width: 80 },
                    { text: "Fecha Vencimiento", value: "DueDateString", width: 80 },
                    // { text: "Condicion", value: "", width: 80 },
					{ text: "T. Congelamiento", value: "CecDescription", width: 80 },
					{ text: "E. Final", value: "StateFinalString", width: 80 },
                ],
                ItemsPrepackingDetailRpt:[],
                processing:false,
                report: {},
			};
		},

		created() {
		},

		methods: {

			close(){
				this.$emit('close')
			},
            listDetail(){
				
                if (this.CppID !== null) {
					_sPrePacking
						.detail({CppID : this.CppID}, this.$fun.getUserID())
						.then((resp) => {
							if (resp.status == 200) {
								this.ItemsPrepackingDetailRpt = resp.data;
								console.log("itemsSenasa", this.ItemsPrepackingDetailRpt);
							}
						});
				}
            },
            exportExcel(){
                let parametervalue = [];

                parametervalue.push(this.CppID)
                // parametervalue.push(this.cDateFin)
                // parametervalue.push(2)
                 
                let parametergen = " @CppID";
                this.downloadReport("REPORTE PREPACKING - CAMARA","FrzChamberPrePackingDetail_Rpt",parametervalue,parametergen);
            },
            downloadReport(QryNameReport,qryname,parametervalue,parametergen) {
				 console.log("QryNameReport",QryNameReport);

				this.report.QryNameReport = QryNameReport	;  
				this.report.QryParamsArray = parametergen	; 
				this.report.QryParamsArrayValue = parametervalue.join(","); 
				this.report.QryNameProcedure = qryname;

				this.processing = true;
				_sQryConfigurationService
					.downloadexcel(this.report, this.$fun.getUserID())
					.then(
						(r) => {
							if (r.status == 200) {
								this.processing = false;

								this.$fun.downloadFile(
									r.data,
									this.$const.TypeFile.EXCEL,
									 QryNameReport
								);
							}
						},
						(e) => {
							this.processing = true;
						}
					);

			 
			},
		},
        created(){
            this.listDetail();
        }
	};
</script>

<style>
</style>