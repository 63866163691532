<template>
	<v-container>
		<div>
			<!-- :single-row="false" -->
			<s-crud
				:filter="filter"
				:config="config"
				search-input
				
				no-full
				height="auto"
				title="REPORTE PRE PACKING"
				ref="crudPackinglist"
				@rowSelected="rowSelected($event)"
				
			>
            <!-- :remove="itemsSelected.PckInitialCharge == null" -->
				<template v-slot:filter>
					<v-container>						
						<v-row style="margin-left: 5px" justify="center">
                            <v-col cols="auto">
                                <s-select-client
                                    clearable
                                    label="Cliente(es):"
                                    v-model="filter.CumID"
                                    ref="txtCumID"
                                    full 
                                >
                                </s-select-client>  
                            </v-col>
							
							<v-col cols="auto">
								<s-select-definition
									clearable
									label="Tipo Empaque"
									v-model="filter.TypePacking"
									:def="1435"
									
								></s-select-definition>
							</v-col>
							<v-col cols="auto">                              
								<s-select-definition
									clearable
									label="Tipo corte"
									:def="1429"
									v-model="filter.TypeCut"
									
								></s-select-definition>  
							</v-col>
							<v-col cols="auto">
								<s-date
									label="Fecha Inicio"
									v-model="filter.DateBegin"
								></s-date>
							</v-col>
							<v-col cols="auto">
								<s-date
									label="Fecha Fin"
									v-model="filter.DateEnd"
								></s-date>
							</v-col>
							<!-- <v-col cols="6" md="3" lg="3">
								<v-btn color="info" small class="mt-4" rounded @click="clickGeneratedShipment()">Generar Embarque</v-btn>
							</v-col> -->
						</v-row>
						<v-row style="margin-left: 5px">
							<v-col cols="6" md="3" lg="3">
								<!-- aqui iria almacenes select -->
							</v-col>
						</v-row>
					</v-container>
				</template>

				
				<!-- <template v-slot:PckInitialShip="{ row }">
					<v-btn
						x-small
						:color="row.PckInitialCharge == null ? 'success' : 'warning'"
						rounded
						@click="clickInitial(row)"
						:disabled="row.PckFinCharge !== null"
					>
						{{row.PckInitialCharge == null ? 'Iniciar' : 'Terminar'}}
					</v-btn>
				</template> -->

				<template v-slot:PckView="{ row }">
					<v-btn
						x-small
						color="error"
						fab
						@click="generatedPrePacking(row, 1)"
					>
						<v-icon style="font-size: 16px !important"
							>fas fa-eye</v-icon
						>
					</v-btn>
				</template>
				<template v-slot:PckGenerated="{ row }">
					<v-btn
						x-small
						color="info"
						fab
						@click="generatedPrePacking(row, 2)"
					>
						<v-icon style="font-size: 16px !important"
							>fa fa-parking</v-icon
						>
					</v-btn>
				</template>
				<template v-slot:Update="{ row }">
					<v-btn x-small 
						color="green"
						fab 
						dark
						@click="UpdatePrepacking(row)"
						>
						<v-icon style="font-size: 20px !important"
							>mdi-pencil-circle</v-icon>
					</v-btn>
				</template>
			</s-crud>

            <v-dialog persistent v-model="openModalPrePackRpt" v-if="openModalPrePackRpt" width="1500">
				<report-pre-packing :CppID="CppID" @close="close()" ></report-pre-packing>
			</v-dialog>

			<v-dialog persistent v-model="UpdatePrePacking" v-if="UpdatePrePacking">
				<ViewPrePacking :SelectPrePacking="SelectPrePacking" @closeUpdate="CloseUpdatePrePacking()"></ViewPrePacking>
			</v-dialog>
		</div>

	</v-container>
</template>

<script>
    import SSelectClient from "@/components/FrozenProduction/Maturation/SSelectClienteMaturation";
    import _sPrePacking from "@/services/FrozenProduction/FrzChamberPrePacking.js";
    import ReportPrePacking from './FrzChamberPrePackingRpt.vue'

	import ViewPrePacking from "../FrzChamberPrepacking/FrzChamberPrePacking.vue"

	

	export default {
		components: { SSelectClient ,ReportPrePacking, ViewPrePacking },
		data: () => ({
			modalEditPrePacking: false,
			itemsSelected: [],
			itemsPrePacking: [],
			openModalPrePack: false,
			filter: {
                CumID : "",
				DateBegin: "",
				DateEnd: ""
			},
			config: {
				service: _sPrePacking,
				model: {
					CppID: "ID",
					PckView: "",
					Update: "",
					TypePacking: "",
					TypeCut: "",

				},
				headers: [
					{ text: "Pre-Packing", value: "PckView", width: 10 },
					{ text: "Editar", value: "Update", width: 30 },
                    { text: "PalletGroupID", value: "CppID", width: 30 },
					{ text: "Embarque", value: "numberShipment", width: 30 },
					{ text: "Fecha", value: "CppDateCreateString" },
					{ text: "N° version", value: "versionnumber", width: 100 },
					{ text: "N° Cajas", value: "NumberBox", width: 100 },
					// { text: "Hora", value: "HourBegin" },
					// { text: "N° Pallet", value: "PalletGroupIDName", width: 80 },
					// { text: "ID Proveedor", value: "RcfIDs"},
					{ text: "ID Trazabilidad", value: "TraceabilityID"},
					{ text: "Cliente(s)", value: "CumName"},
					// { text: "Marca", value: "CbdBrans", width: 100 },
					{ text: "Cultivo", value: "TypeCultiveName", width: 100 },
					// { text: "Variedad", value: "VrtDescription"},
					{ text: "Tipo Corte", value: "TypeCutName", width: 100 },
					{ text: "Presentacion.", value: "TypePackingName", width: 100 },
					// { text: "Tipo Congelamiento", value: "CecDescription" },
                    
				]
			},
            openModalPrePackRpt : false,
            CppID: 0,
			UpdatePrePacking : false,
			SelectPrePacking : {}
		}),

		watch: {
		
		},

		methods: {


			closeAll(){
				this.openModalPrePack = false
				this.$refs.crudPackinglist.refresh();
			},

			rowSelected(items){

				if(items !== undefined){
					if(items.length > 0){
						this.itemsSelected = items[0];						
					}
				}
				
			},
			generatedPrePacking(item) {
				this.CppID = item.CppID                
                this.openModalPrePackRpt = true;
			},
            close(){
                this.openModalPrePackRpt = false;
				this.$refs.crudPackinglist.refresh();
            },
			UpdatePrepacking(row){
				this.SelectPrePacking = {};
				this.UpdatePrePacking = true;
				this.SelectPrePacking = row;
				
			},
			CloseUpdatePrePacking()
			{
				this.UpdatePrePacking = false;	
				this.$refs.crudPackinglist.refresh();			
			}
		},

	};
</script>
